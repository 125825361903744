import React from "react";
import PricingPlan from "../pricing";
import { FreeType, FreeCost, FreeTime, MonthType, MonthTime, MonthCost, YearType, YearCost, YearTime, MonthTwoCost, MonthThreeCost } from '../../constants';
import { useNavigate } from "react-router-dom";
import { Card } from "flowbite-react";

const SlideFour = () => {

    const navigate = useNavigate();

    function redirectContact() {
        navigate("/contact");
    }


    const freeData = `{"type": "${FreeType}", "cost": "${FreeCost}", "time": "${FreeTime}", "one": "Generate 5 Sub-Topics", "two": ${1}, "three": "Video & Theory Course", "four": "Lifetime access", "five": "Theory & Image Course"}`;
    const parsedFreeData = JSON.parse(freeData);

    const monthData = `{"type": "${MonthType}", "cost": "${MonthCost}", "time": "/${MonthTime}", "one": "Generate 10 Sub-Topics", "two": ${2}, "three": "Video & Theory Course", "four": "1 Month Access", "five": "Theory & Image Course"}`;
    const parsedMonthData = JSON.parse(monthData);

    const monthTwoData = `{"type": "${MonthType}", "cost": "${MonthTwoCost}", "time": "/${MonthTime}", "one": "Generate 10 Sub-Topics", "two": ${5}, "three": "Video & Theory Course", "four": "1 Month Access", "five": "Theory & Image Course"}`;
    const parsedMonthTwoData = JSON.parse(monthTwoData);

    const monthThreeData = `{"type": "${MonthType}", "cost": "${MonthThreeCost}", "time": "/${MonthTime}", "one": "Generate 10 Sub-Topics", "two": ${10}, "three": "Video & Theory Course", "four": "1 Month Access", "five": "Theory & Image Course"}`;
    const parsedMonthThreeData = JSON.parse(monthThreeData);

    return (
        <div className="dark:bg-black py-14">
            <div className="px-11 items-start justify-start text-start">
                <div className="text-4xl max-md:text-2xl font-black dark:text-white">Pricing</div>
                <p className="py-3 font-medium max-md:text-xs dark:text-white">Choose the right plan for your education and future</p>
            </div>
            <div className="lg:flex py-10">

                <div className="flex flex-col items-center justify-center lg:w-1/3 max-md:pt-4">
                    <PricingPlan data={parsedFreeData} />
                </div>

                <div className="flex flex-col items-center justify-center lg:w-1/3 max-md:pt-4">
                    <PricingPlan data={parsedMonthData} />
                </div>

                <div className="flex flex-col items-center justify-center lg:w-1/3 max-md:pt-4">
                    <PricingPlan data={parsedMonthTwoData} />
                </div>

            </div>
            <div className="lg:flex py-14">
                <div className="flex flex-col items-center justify-center lg:w-1/2 px-3 max-md:pt-4">
                    <PricingPlan data={parsedMonthThreeData} />
                </div>

                <div className="flex flex-col items-center justify-center lg:w-1/2 px-3 max-md:pt-4">
                    <Card className='max-w-sm shadow-none border border-black rounded-none mt-3 px-14 py-5 dark:bg-black dark:border-white'>
                        <h5 className="mb-4 text-3xl max-md:text-2xl font-black text-black dark:text-white text-center">Enterprise Plan</h5>
                        <button
                            onClick={redirectContact}
                            className="inline-flex w-full justify-center dark:bg-white dark:text-black bg-black px-5 py-2.5 text-center text-sm font-bold text-white "
                        >Contact Us
                        </button>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default SlideFour;
