import React, { useEffect } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import UserCourses from '../components/usercourses';
import axios from 'axios';
import { serverURL } from '../constants';

const Home = () => {

    useEffect(() => {
        checkIfPaid();
    }, []);

    const runCommand = async () => {
        let uid = sessionStorage.getItem('uid');
        const postURL = serverURL + `/api/status?uid=${uid}`;
        const response = await axios.get(postURL);
        if (response.data.success) {
            sessionStorage.setItem('count', 1);
            sessionStorage.setItem('type', 'free');
        } else {
            checkCount();
        }
        sessionStorage.setItem('lastRunDate', true);
    };

    const onAppOpen = async () => {
        const shouldRun = sessionStorage.getItem('lastRunDate');
        if (!shouldRun) {
            await runCommand();
        }
    };

    async function checkIfPaid() {
        const typeUser = sessionStorage.getItem('type');
        if (typeUser !== 'free') {
            onAppOpen();
        } else {
            checkCount();
        }
    }

    async function checkCount() {
        let uid = sessionStorage.getItem('uid');
        const postURL = serverURL + `/api/counter?uid=${uid}`;
        const response = await axios.get(postURL);
        if (response.data.success) {
            sessionStorage.setItem('count', response.data.message);
        } else {
            sessionStorage.setItem('count', 0);
        }
    }

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={true} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='pb-10 mx-2'>
                    <UserCourses userId={sessionStorage.getItem('uid')} />
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default Home;
